.Toastify__toast {
    border-radius: 10px;
    padding: 20px;
    color: #fff;
    font-size: 16px;
}

.Toastify__toast--success {
    background: #5cb85c;
}

.Toastify__toast--error {
    background: #d9534f;
}
