html {
    margin: 0;
    min-height: 100%;
    width: 100%;
    scroll-behavior: smooth;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
    font-family: -apple-system, BlinkMacSystemFont, "Ubuntu", "Segoe UI", "Roboto", "Oxygen", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

header {
    margin: 0;
    z-index: 1;
}

body {
    min-height: 100%;
    margin: 0;
    z-index: 1;
}

body::-webkit-scrollbar {
    display: none;
}

footer {
    z-index: 1;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

:root {
    --color-background-light: #ffffff;
    --color-background-dark: #ffffff;
    --color-text-light: #ffffff;
    --color-text-dark: #ffffff;
}
