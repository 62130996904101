@keyframes in {
    0% {
        opacity: 0;
        margin-top: 15%;
    }
    100% {
        opacity: 1;
        margin-top: 18px;
    }
}

hr {
    width: calc(min(500px, 90vw));
    z-index: 1;
    border: 2px solid #ffffff10;
    border-radius: 18px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin-top: 35px;
    margin-bottom: 35px;
}

.animate-section {
    animation: in 2.3s ease-in-out;
}

h1 {
    font-size: calc(36px + 2vmin);
    margin: 0;
}

h2 {
    font-size: calc(8px + 1vmin);
    margin: 0;
}

.App {
    width: 100vw;
    height: 100%;
    overflow: hidden;
    background-color: #0f0f0f;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: url('./custom-cursor.cur'), auto;
}

.App-link {
    text-decoration: underline;
    color: white;
    transition: all 0.25s ease-in-out;
}

.App-link:hover,
.App-link:focus {
    color: #2d8f9e;
}

/*.App-background {
  width: 100%;
  height: 100%;
  top: 0%;
  position: fixed;
  z-index: -1;
  background: linear-gradient(-69deg, #4c39b2, #2d8f9e, #10525c);
  background-size: 400% 400%;
  background-attachment: fixed;
  animation: Gradient 24s ease infinite;
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}*/

h2 {
    font-size: calc(8px + 1vmin);
    margin: 0;
    margin-bottom: 20px; // Adjust this value as needed
}

h2:last-of-type {
    margin-bottom: 20px;
}

h2:first-of-type {
    margin-top: 0px;
}

h4 {
    font-size: calc(6px + 1vmin);
    margin: 0;
    margin-bottom: 20px; // Adjust this value as needed
}

.App-background {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(160deg, #007cf0, #d8e1ac);
    animation: Gradient 3s ease infinite;
    background-size: 200% 200%;
}

.App-background-dark {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(160deg, #002b54, #595d47);
    animation: Gradient 3s ease infinite;
    background-size: 200% 200%;
}

@keyframes Gradient {
    0% {
        background-position: 100% 0%;
    }
    50% {
        background-position: 0% 100%;
    }
    100% {
        background-position: 100% 0%;
    }
}

// $particleSize: 20vmin;
// $animationDuration: 6s;
// $amount: 36;
// .App-background span {
//     width: $particleSize;
//     height: $particleSize;
//     border-radius: $particleSize;
//     backface-visibility: hidden;
//     position: absolute;
//     animation-name: move;
//     animation-duration: $animationDuration;
//     animation-timing-function: linear;
//     animation-iteration-count: infinite;
//     $colors: (#7d45fb, #0aab91, #005f62);

//     @for $i from 1 through $amount {
//         &:nth-child(#{$i}) {
//             color: nth($colors, random(length($colors)));
//             top: random(100) * 1%;
//             left: random(100) * 1%;
//             animation-duration: (random($animationDuration * 10) / 10) * 1s + 10s;
//             animation-delay: random(($animationDuration + 10s) * 10) / 10 * -1s;
//             transform-origin: (random(50) - 25) * 1% (random(50) - 25) * 1%;
//             $blurRadius: (random() + 0.5) * $particleSize * 0.5;
//             $x: if(random() > 0.5, -1, 1);
//             box-shadow: ($particleSize * 2 * $x) 0 $blurRadius currentColor;
//         }
//     }
// }

@keyframes move {
    100% {
        transform: translate3d(0, 0, 1px) rotate(360deg);
    }
}
.white-text {
    color: white;
}

.light {
    background-color: white;
    color: black;
}

.dark {
    background-color: black;
    color: white;
}

.customFileUpload {
    background: #5e5df0;
    border-radius: 999px;
    box-shadow: #5e5df0 0 10px 20px -10px;
    box-sizing: border-box;
    color: #ffffff;
    cursor: pointer;
    font-family: Inter, Helvetica, "Apple Color Emoji", "Segoe UI Emoji", NotoColorEmoji, "Noto Color Emoji",
        "Segoe UI Symbol", "Android Emoji", EmojiSymbols, -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue",
        "Noto Sans", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    opacity: 1;
    outline: 0 solid transparent;
    padding: 8px 18px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: fit-content;
    word-break: break-word;
    border: 0;
}
.App-item {
    margin: 18px;
    padding: 24px;
    height: max-content;
    width: 100%;
    font-size: calc(14px + 1.5vmin);
    background-color: #ffffff10;
    color: white;
    text-decoration: none;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 24px;
    animation: in 1.5s ease-in-out;
}
.App-item.dark {
    margin: 18px;
    padding: 24px;
    height: max-content;
    width: 100%;
    font-size: calc(14px + 1.5vmin);
    background-color: #ff000010;
    color: grey;
    text-decoration: none;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 24px;
    animation: in 1.5s ease-in-out;
}

.App-header {
    width: calc(min(350px, 60vw));
    margin-top: 3%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
}

.header-image {
    width: 80%;
    margin-top: 3%;
    height: auto;
    border-radius: 25%;
    transition: all 1s ease-in-out;
}

.header-title {
    margin-bottom: 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.header-image[clicked="1"] {
    transform: rotate(-180deg);
}

.header-typewriter {
    height: 2em;
    margin-bottom: 3%;
}

.App-body {
    width: calc(min(500px, 90vw));
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.links {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.link {
    width: 75%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transition: all 0.25s ease-in-out;
}

.link:hover,
.link:focus {
    outline-color: #ffffff60;
    background-color: #ffffff30;
    outline-style: solid;
    transform: scale(1.05);
    margin: 25px;
    /*background-color: #00000015;*/
}

.link-img {
    width: 15%;
    height: auto;
    padding-right: 3vmin;
}

.link-invert {
    filter: invert(100%);
}

.clicked-links {
    animation: in 1.5s ease-in-out;
}

.App-footer {
    width: calc(min(500px, 80vw));
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(7px + 1.5vmin);
}

.footer-img {
    margin: 18px;
    transition: all 0.25s ease-in-out;
}

.footer-img:hover,
.footer-img:focus {
    transform: scale(1.1);
}

.footer-img[heart="1"] {
    animation: heart 0.25s ease-in-out;
}

@keyframes heart {
    50% {
        transform: scale(0.6);
    }
    100% {
        transform: scale(1.1);
    }
}
